import { http } from '@/api/Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type {
  IMissionListParams,
  IMissionListResponse,
  IMissionItem,
  IMissionParams,
  IMissionGameType,
  IMissionGameListParams,
  IMissionGameListResponse
} from '@/api/schema';
import { EMissionStatus } from '@/api/schema';

export async function getMissionList(
  params: IMissionListParams
): Promise<IMissionListResponse> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/mission'), { params, cacheTime: 0 })
    .then(({ data }: AxiosResponse): IMissionListResponse => data);
}

export async function getMissionById(missionId: string): Promise<IMissionItem> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/mission/${missionId}`))
    .then(({ data }: AxiosResponse): IMissionItem => data);
}

export async function updateMissionStatus(
  missionId: number,
  status: EMissionStatus
): Promise<void> {
  return http.patch(
    buildUrl(`/api/v1/{operatorId}/mission/${missionId}`),
    null,
    {
      params: { status }
    }
  );
}

export async function editMission(
  missionId: string,
  params: IMissionParams
): Promise<void> {
  return http
    .put(buildUrl(`/api/v1/{operatorId}/mission/${missionId}`), params)
    .then(({ data }: AxiosResponse): void => data);
}

export async function createMission(
  _: null,
  params: IMissionParams
): Promise<void> {
  return http
    .post(buildUrl(`/api/v1/{operatorId}/mission`), params)
    .then(({ data }: AxiosResponse): void => data);
}

export async function getGameTypeList(
  missionId?: number
): Promise<IMissionGameType[]> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/mission/game-type'), {
      params: {
        missionId
      }
    })
    .then(({ data }: AxiosResponse): IMissionGameType[] => data);
}

export async function updateGameTypes(
  missionId: number,
  ids: string[]
): Promise<number> {
  return http
    .put(
      buildUrl(`/api/v1/{operatorId}/mission/${missionId}/game-type`),
      null,
      {
        params: {
          ids
        },
        paramsSerializer: {
          indexes: null
        }
      }
    )
    .then(({ data }: AxiosResponse): number => data);
}

export async function uploadMissionImage(
  missionId: number,
  formData: FormData
): Promise<void> {
  return http
    .put(buildUrl(`/api/v1/{operatorId}/mission/${missionId}/image`), formData)
    .then(({ data }: AxiosResponse): void => data);
}

export async function deleteMissionImage(missionId: number): Promise<void> {
  return http
    .delete(buildUrl(`/api/v1/{operatorId}/mission/${missionId}/image`))
    .then(({ data }: AxiosResponse): void => data);
}

export async function getGamesList(
  params: IMissionGameListParams
): Promise<IMissionGameListResponse> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/mission/game'), { params })
    .then(({ data }: AxiosResponse): IMissionGameListResponse => data);
}

export async function updateGameWhiteList(
  type: 'include' | 'exclude',
  missionId: string,
  ids: string[]
): Promise<void> {
  return http
    .post(
      buildUrl(`/api/v1/{operatorId}/mission/${missionId}/game/${type}`),
      null,
      {
        params: {
          ids
        },
        paramsSerializer: {
          indexes: null
        }
      }
    )
    .then(({ data }: AxiosResponse): void => data);
}
