



































































import throttle from 'lodash/throttle';

import CropperDialog from '@/components/core/CropperDialog.vue';

import { errorToastMessage } from '@/helpers/errorToastMessage';
import { urlToBlob } from '@/helpers/blobHelper';
import { PropType } from 'vue';
import * as vr from '@/helpers/validation';
import { ValidationRule } from '@/api/schema';

interface CropResult {
  canvas: HTMLCanvasElement;
  cropCoordinates: any;
}

const ACCEPTED_TYPE = ['image/webp', 'image/jpeg', 'image/jpg'];

export default {
  name: 'ImageUploader',
  components: {
    CropperDialog
  },
  props: {
    label: {
      type: String,
      default: 'Upload banner'
    },
    imageUrl: {
      type: String,
      default: ''
    },
    accept: {
      type: Array as PropType<string[]>,
      default: (): string[] => ACCEPTED_TYPE
    },
    loading: {
      type: Boolean,
      default: false
    },
    imageWidth: {
      type: Number,
      default: 338
    },
    imageHeight: {
      type: Number,
      default: 268
    },
    rules: {
      type: Array,
      default: (): [] => []
    },
    hint: {
      type: String,
      default: ''
    },
    maxFileSize: {
      type: Number,
      default: 1024 * 1024 * 3
    },
    previewMaxHeight: {
      type: String,
      default: 'none'
    },
    hidePreview: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: 'mdi-paperclip'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      isImageEdit: false,
      isLocalImageLoading: false,
      localImageUrl: null,
      localImageBlob: null,
      cropImage: {
        src: null,
        type: null
      },
      cropCanvas: null,
      cropCoordinates: null,
      showCropDialog: false,
      fileDimensionSize: 1000
    };
  },
  watch: {
    imageUrl: {
      immediate: true,
      handler(payload: string): void {
        if (payload) {
          this.setLocalImageFromUrl(payload);
          this.isImageEdit = true;
        }
      }
    },

    localImageUrl(image: string): void {
      this.$emit('change:local-image', image);
    }
  },
  computed: {
    hasError(): boolean {
      if (!this.$refs?.fileInput) return false;

      return !this.$refs?.fileInput?.valid;
    },
    fileSize(): number {
      return (this.maxFileSize / 1024) * this.fileDimensionSize;
    },
    aspectRatio(): number {
      return this.imageWidth / this.imageHeight;
    },
    hasFile(): boolean {
      return !!this.localImageBlob;
    },
    fileRules(): ValidationRule[] {
      return [
        ...this.rules,
        (v) => vr.fileMaxSize(v, this.fileSize, this.hint),
        (v) => this.validateType(v) || this.hint
      ];
    }
  },
  methods: {
    validateType(v: Blob): boolean {
      if (!v || !this.accept.length) return true;

      return this.accept.includes(v.type);
    },
    validateSize(v: Blob): boolean {
      if (!v) return true;

      return typeof vr.fileMaxSize(v, this.fileSize, this.hint) !== 'string';
    },
    async setLocalImageFromUrl(url: string): Promise<void> {
      try {
        this.isLocalImageLoading = true;

        this.localImageUrl = null;

        const blob = await urlToBlob(url);
        blob.name = this.getFileNameFromUrl(url);

        this.localImageUrl = url;
        this.localImageBlob = blob;
        this.cropImage = {
          src: URL.createObjectURL(blob),
          type: blob.type
        };

        await this.handlerOnChangeBlob();
      } catch (e) {
        console.log('setLocalImageFromUrl: ', e);
        errorToastMessage(e);
      } finally {
        this.isLocalImageLoading = false;
      }
    },
    handleDragNDrop(file: Blob): void {
      this.localImageBlob = file;
      this.handlerOnInputFile(file);
    },
    handlerOnInputFile: throttle(
      async function (file: Blob): Promise<void> {
        if (!file || !this.validateType(file) || !this.validateSize(file)) {
          return;
        }

        const url = URL.createObjectURL(file);

        this.cropImage = {
          src: url,
          type: file.type
        };

        this.showCropDialog = true;
      },
      500,
      { leading: true }
    ),
    handlerOnSubmitCrop(payload: CropResult): void {
      const { canvas, cropCoordinates } = payload;

      this.cropCanvas = canvas;
      this.cropCoordinates = cropCoordinates;

      this.isImageEdit = false;
      this.localImageUrl = `${canvas.toDataURL(this.cropImage.type)}`;

      this.handlerOnChangeBlob();
    },
    handlerOnCropClose(): void {
      if (!this.localImageUrl) this.localImageBlob = null;

      this.handlerOnChangeBlob();
    },
    async handlerOnChangeBlob(): Promise<void> {
      try {
        let blob: Blob;

        if (this.cropCanvas) {
          blob = await new Promise((resolve) => {
            this.cropCanvas.toBlob((blob) => {
              resolve(blob);
            }, this.cropImage.type);
          });
        } else {
          blob = this.localImageBlob;
        }

        this.$emit('change', blob);
      } catch (e) {
        console.log('handlerOnChangeBlob: ', e);
        errorToastMessage(e);
      }
    },
    clearConfiguration(): void {
      this.localImageBlob = null;
      this.localImageUrl = null;

      this.cropCanvas = null;

      if (this.cropImage?.src) {
        this.isImageEdit = false;
        this.cropCoordinates = null;
        URL.revokeObjectURL(this.cropImage.src);
      }

      this.$emit('change', null);
    },
    getFileNameFromUrl(url: string): string {
      return url.split('/').pop() || '';
    }
  }
};
