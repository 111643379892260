































































































































































































































































































































import {
  EMissionCountableType,
  EMissionBetConditionType,
  EMissionSegment,
  EMissionConditions,
  SelectItem,
  ValidationRules
} from '@/api/schema';

import {
  MAX_FILE_SIZE,
  IMAGE_REQUIREMENTS,
  defaultFormData,
  segmentOptions,
  conditionOptions,
  rewardTypeOptions,
  countryOptions,
  deviceOptions,
  excludedInFormData
} from '@/helpers/missionHelpers';

import { mapGetters } from 'vuex';

import {
  createMission,
  editMission,
  getGameTypeList,
  updateGameTypes,
  uploadMissionImage,
  deleteMissionImage,
  updateGameWhiteList,
  getMissionById
} from '@/api/Missions';

import * as vr from '@/helpers/validation';
import { errorToastMessage } from '@/helpers/errorToastMessage';

import Tooltip from '@/components/tooltip/Tooltip.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import MissionDetailsSummary from '@/views/MissionsView/MissionDetails/MissionDetailsSummary.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';

import MissionDetailsManageGamesModal from '@/views/MissionsView/MissionDetails/MissionDetailsManageGamesModal.vue';
import MissionDetailsManageUsersModal from '@/views/MissionsView/MissionDetails/MissionDetailsManageUsersModal.vue';
import { toTitleCase } from '@/helpers/formatString';
import { omit } from 'lodash';

export default {
  name: 'MissionDetailsView',
  components: {
    MissionDetailsManageUsersModal,
    MissionDetailsManageGamesModal,
    ImageUploader,
    Tooltip,
    VExtendedDataPicker,
    MissionDetailsSummary
  },
  data(): any {
    return {
      EMissionSegment,
      EMissionCountableType,
      EMissionBetConditionType,
      EMissionConditions,
      MAX_FILE_SIZE,
      IMAGE_REQUIREMENTS,

      loading: false,
      formData: { ...defaultFormData },

      uploadImageFile: null,
      localImage: null,
      validForm: false,

      gameTypes: {
        list: [],
        selected: []
      },

      gameWhiteList: {
        included: [],
        excluded: []
      },

      dialogs: {
        manageGames: false,
        manageUsers: false
      }
    };
  },

  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    segmentOptions: (): SelectItem[] => segmentOptions,
    conditionOptions: (): SelectItem[] => conditionOptions,
    rewardTypeOptions: (): SelectItem[] => rewardTypeOptions,
    countryListOptions: (): SelectItem[] => countryOptions,
    deviceOptions: (): SelectItem[] => deviceOptions,

    countableOptions(): SelectItem[] {
      return [
        {
          text: 'Count of events',
          value: true
        },
        {
          text: 'Amount of events',
          value: false,
          disabled: [
            EMissionConditions.H2E,
            EMissionConditions.BET_VOLUME
          ].includes(this.formData.condition)
        }
      ];
    },

    pageTitle(): string {
      if (this.isReadonly) return 'Mission Details';

      return this.isCreated ? 'Create Mission' : 'Edit Mission';
    },

    showConditionSelect(): boolean {
      return [EMissionConditions.BET, EMissionConditions.BET_VOLUME].includes(
        this.formData.condition
      );
    },

    gamesCount(): number {
      const { included, excluded } = this.gameWhiteList;
      const { gamesCount } = this.formData;

      const localCount = included.length - excluded.length;

      return gamesCount ? gamesCount + localCount : localCount;
    },

    missionId(): number | null {
      return +this.$route.params.missionId || null;
    },

    isCreated(): boolean {
      return !this.missionId;
    },

    isReadonly(): boolean {
      return !!this.$route.meta.readonly;
    },

    rules(): ValidationRules {
      const { manageUsers, manageGames } = this.dialogs;
      const { betConditionType } = this.formData;

      return {
        bannerImg: [
          (v) => vr.fileMaxSize(v, MAX_FILE_SIZE, IMAGE_REQUIREMENTS)
        ],
        name: [vr.required],
        segment: [vr.required],
        usersList: !manageUsers
          ? [(v: string) => !!v || 'Users list is required']
          : [],
        gameList: !manageGames
          ? [(v: string) => !!v || 'Game list is required']
          : [],
        conditions: [vr.required],
        gameTypes:
          betConditionType === EMissionBetConditionType.GAME_TYPE
            ? [(v) => vr.required(v.length || null)]
            : [],
        conditionAmount: [vr.required, vr.validNumber],
        rewardType: [vr.required],
        rewardAmount: [vr.required, vr.validNumber],
        startDate: [vr.required],
        dueDate: [vr.required]
      };
    },

    disableButton(): boolean {
      return !this.validForm || this.isReadonly;
    }
  },

  watch: {
    operatorId: {
      handler(id: string): void {
        if (!id) return;

        this.getGameTypes();

        if (!this.isCreated) this.getMission();
      },
      immediate: true
    },
    'formData.condition': {
      handler(): void {
        this.formData.countable = true;
      }
    },
    'formData.countable': {
      handler(): void {
        ['noAccumulation', 'oncePerDayCount'].forEach(
          this.resetDependentField
        );
      }
    }
  },

  methods: {
    handleClickBackButton(): void {
      this.$router.push({ name: 'account-operator-missions' });
    },

    openManageGamesModal(): void {
      this.dialogs.manageGames = true;
    },

    closeManageGamesModal(): void {
      this.dialogs.manageGames = false;
    },

    openManageUsersModal(): void {
      this.dialogs.manageUsers = true;
    },

    closeManageUsersModal(): void {
      this.dialogs.manageUsers = false;
    },

    inputFileHandler(file: Blob): void {
      this.uploadImageFile = file;
    },

    setLocalImage(image: string): void {
      this.localImage = image;
    },

    resetDependentField(key: string): void {
      if (this.formData[key]) this.formData[key] = false;
    },

    setSelectedGames(games: {
      included: number[];
      excluded: number[];
    }): void {
      this.gameWhiteList = { ...games };
      this.dialogs.manageGames = false;
    },

    updateUserIds(ids: string[]): void {
      this.formData.operatorUserIds = ids;
      this.dialogs.manageUsers = false;
    },

    uploadImage(missionId: number): void {
      const imageFormData = new FormData();
      imageFormData.append('image', this.uploadImageFile);

      uploadMissionImage(missionId, imageFormData).catch(errorToastMessage);
    },

    deleteImage(missionId: number): void {
      deleteMissionImage(missionId).catch(errorToastMessage);
    },

    updateImage(id: number): void {
      const { missionImage } = this.formData;

      if (!this.localImage && missionImage) {
        this.deleteImage(id);
      } else if (this.localImage !== missionImage) {
        this.uploadImage(id);
      }
    },

    updateGameWhiteList(id: string): void {
      const { included, excluded } = this.gameWhiteList;

      if (included.length) updateGameWhiteList('include', id, included);

      if (excluded.length) updateGameWhiteList('exclude', id, excluded);
    },

    getGameTypes(): void {
      getGameTypeList(this.missionId).then((response) => {
        this.gameTypes.list = response.map((el) => ({
          text: toTitleCase(el.type),
          value: el.id,
          included: el.included
        }));

        if (!this.isCreated) {
          this.gameTypes.selected = response
            .filter((item) => item.included)
            .map(({ id }) => id);
        }
      });
    },

    updateGameTypes(id: number): void {
      updateGameTypes(id, this.gameTypes.selected).catch(errorToastMessage);
    },

    getMission(): void {
      this.loading = true;

      getMissionById(this.missionId)
        .then((response) => {
          Object.assign(this.formData, omit(response, excludedInFormData));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveMission(): void {
      const { betConditionType } = this.formData;

      const action = this.missionId ? editMission : createMission;

      action(this.missionId, this.formData)
        .then((id) => {
          this.updateImage(id);

          if (betConditionType === EMissionBetConditionType.GAME_TYPE) {
            this.updateGameTypes(id);
          }

          if (betConditionType === EMissionBetConditionType.GAME) {
            this.updateGameWhiteList(id);
          }

          if (this.isCreated) {
            this.$toast.success(
              `Mission “${this.formData.name}” was successfully created`
            );
          } else {
            this.$toast.success(
              `Mission “${this.formData.name}” has been successfully updated`
            );
          }

          this.handleClickBackButton();
        })
        .catch(errorToastMessage);
    }
  }
};
