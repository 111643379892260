




























import { textOverflowMiddle } from '@/helpers/formatString';
import BaseDialog from '@/components/BaseDialog.vue';
import IdsListManage from '@/components/IdsListManage.vue';

export default {
  name: 'MissionDetailsManageUsersModal',
  components: {
    IdsListManage,
    BaseDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },

    userIds: {
      type: Array,
      default: (): string[] => []
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },

  data(): any {
    return {
      users: this.userIds
    };
  },

  methods: {
    textOverflowMiddle,

    setIdsList(ids: string[]): void {
      this.users = ids;
    },

    handleClickCancelButton(): void {
      this.$emit('close');
    },

    handleClickApplyButton(): void {
      this.$emit('submit', this.users);
    }
  }
};
