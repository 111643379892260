


























import {
  SEGMENT_OPTIONS,
  CONDITION_OPTIONS,
  REWARD_TYPE_OPTIONS,
  deviceOptions
} from '@/helpers/missionHelpers';

export default {
  name: 'MissionDetailsSummary',

  props: {
    summary: {
      type: Object,
      default: (): any => ({})
    },
    localImage: {
      type: String,
      default: ''
    }
  },

  computed: {
    summaryList(): any {
      return [
        {
          label: 'Mission name',
          value: this.summary.name
        },
        {
          label: 'Audience',
          value: SEGMENT_OPTIONS[this.summary.segment]
        },
        {
          label: 'Location',
          value: this.summary.location.length
        },
        {
          label: 'Device',
          value: this.formatDevice
        },
        {
          label: 'Event type',
          value: CONDITION_OPTIONS[this.summary.condition]
        },
        {
          label: this.summary.countable
            ? 'Count of events'
            : 'Amount of events',
          value: this.summary.conditionAmount
        },
        {
          label: 'No accumulation',
          value: 'On',
          hide: !this.summary.noAccumulation
        },
        {
          label: 'Once per day',
          value: 'On',
          hide: !this.summary.oncePerDayCount
        },
        {
          label: 'Reward type',
          value: REWARD_TYPE_OPTIONS[this.summary.rewardType]
        },
        {
          label: 'Reward amount',
          value: this.summary.rewardAmount
        },
        {
          label: 'Start date',
          value: this.summary.startDate
        },
        {
          label: 'Due date',
          value: this.summary.dueDate
        }
      ]
        .filter((item) => !item.hide)
        .map((item) => ({ ...item, value: item.value || '-' }));
    },

    formatDevice(): string {
      return deviceOptions
        .filter(({ value }) => this.summary.device.includes(value))
        .map(({ text }) => text)
        .join(', ');
    },

    preview(): string {
      if (this.localImage) return this.localImage;

      if (this.summary.image) return this.summary.image;

      return require('@/assets/images/box.png');
    }
  }
};
