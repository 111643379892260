





























import BaseDialog from '@/components/BaseDialog.vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.bubble.css';

export default {
  name: 'CropperDialog',
  components: {
    BaseDialog,
    Cropper
  },
  props: {
    value: {
      type: Boolean,
      default: (): boolean => false
    },
    submitText: {
      type: String,
      default: 'Save'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    header: {
      type: String,
      default: 'Crop image'
    },
    widthDialog: {
      type: String,
      default: '700px'
    },
    coordinates: {
      type: Object,
      required: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  },
  mounted(): void {
    if (this.isEdit) {
      this.$refs?.cropper.setCoordinates(({ imageSize }) => ({
        ...imageSize
      }));

      return;
    }
    if (this.coordinates) {
      this.$refs?.cropper.setCoordinates(() => ({ ...this.coordinates }));
    }
  },
  methods: {
    submitHandler(): void {
      const result = this.$refs.cropper.getResult();

      this.$emit('submit', result);
      this.close();
    },
    close(): void {
      this.showDialog = false;
      this.$emit('close');
    }
  }
};
