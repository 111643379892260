
























































































import { mapActions, mapState } from 'vuex';
import { AxiosError } from 'axios';
import { DataTableHeader } from 'vuetify';
import {
  SelectItem,
  IMenuFilterItem,
  GameProviderData,
  IPromoGameBlackListItem,
  IMissionGame
} from '@/api/schema';

import { errorToastMessage } from '@/helpers/errorToastMessage';
import { getGamesList } from '@/api/Missions';

import BaseDialog from '@/components/BaseDialog.vue';
import VMenuFilter from '@/components/VMenuFilter.vue';
import { toTitleCase } from '@/helpers/formatString';

import { pluralize } from '@/helpers/pluralize';

interface ISelectedTable {
  item?: IPromoGameBlackListItem;
  items?: IPromoGameBlackListItem[];
  value: boolean;
}

enum ESearchByOptions {
  GAME_NAME = 'name',
  GAME_CODE = 'code'
}

const defaultOptions = {
  page: 1,
  itemsPerPage: 8
};

export default {
  name: 'MissionDetailsManageGamesModal',

  components: {
    BaseDialog,
    VMenuFilter
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    isCreated: {
      type: Boolean,
      default: false
    },
    missionId: {
      type: Number,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data(): any {
    return {
      options: { ...defaultOptions },
      filter: {
        searchBy: ESearchByOptions.GAME_NAME,
        search: '',
        providers: []
      },
      loading: true,
      rows: [],
      selectedGames: [],
      changedItems: {},
      totalElements: null
    };
  },

  computed: {
    ...mapState('Onboarding', ['providers']),

    providersList(): IMenuFilterItem[] {
      return this.providers.map((provider: GameProviderData) => ({
        text: toTitleCase(provider.name),
        value: provider.name
      }));
    },

    tableHeaders(): DataTableHeader[] {
      return [
        {
          text: 'Game Code',
          value: 'code'
        },
        {
          text: 'Game Name',
          value: 'name'
        },
        {
          text: 'Game Provider',
          value: 'provider'
        }
      ];
    },

    searchByOptions(): SelectItem[] {
      return [
        {
          text: 'Game Name',
          value: ESearchByOptions.GAME_NAME
        },
        {
          text: 'Game Code',
          value: ESearchByOptions.GAME_CODE
        }
      ];
    },

    currentSearchBy(): SelectItem {
      return this.searchByOptions.find(
        (el: SelectItem) => el.value === this.filter.searchBy
      );
    },

    searchPlaceholder(): string {
      return `Search by ${this.currentSearchBy.text}`;
    },

    selectedProviders(): string[] {
      return this.filter.providers.map((item) => item.value);
    },

    requestParams(): any {
      const { searchBy, search } = this.filter;

      return {
        [searchBy]: search || null,
        missionId: this.missionId,
        providers: this.selectedProviders,
        page: this.options.page - 1,
        size: this.options.itemsPerPage
      };
    },

    excludedIds(): string[] {
      return this.getChangedItemsIds(false);
    },

    includedIds(): string[] {
      return this.getChangedItemsIds(true);
    },

    disabledButton(): boolean {
      return !(this.excludedIds.length || this.includedIds.length);
    }
  },

  watch: {
    options: 'updateGamesBlacklist',

    value(open: boolean): void {
      if (open && !this.providers.length) this.getProviders();
    }
  },

  methods: {
    ...mapActions('Onboarding', ['getProviders']),
    toTitleCase,

    updateFilter(): void {
      this.updateGamesBlacklist();
      this.resetSelectedGames();
      this.resetChangeItems();
      this.resetPage();
    },

    updateGamesBlacklist(): void {
      this.loading = true;

      getGamesList(this.requestParams)
        .then((response) => {
          const { content, totalElements } = response;

          const list = this.isCreated
            ? content.map((el) => ({ ...el, included: false }))
            : content;

          this.rows = list;
          this.totalElements = totalElements;

          this.setInitialSelectedItems(list);
        })
        .catch((err: AxiosError) => errorToastMessage(err))
        .finally(() => {
          this.loading = false;
        });
    },

    checkChangedItems(item: IMissionGame): boolean {
      const excludedItem = this.excludedIds.includes(item.id);
      const includedItem = this.includedIds.includes(item.id);

      if (excludedItem) return excludedItem;

      if (includedItem) return !includedItem;

      return item.included;
    },

    setInitialSelectedItems(items: IMissionGame[]): void {
      this.selectedGames = items.filter(this.checkChangedItems);
    },

    changeSelectedItem({ item, value }: ISelectedTable): void {
      this.setSelectedItem(item, value);
    },

    changeSelectedAllItems({ items, value }: ISelectedTable): void {
      items.forEach((item) => this.setSelectedItem(item, value));
    },

    setSelectedItem(item: IMissionGame, value: boolean): void {
      const initialItem = this.rows.find(
        (row: IMissionGame) => row.id === item.id
      );

      initialItem.included !== value
        ? this.$set(this.changedItems, item.id, value)
        : this.$delete(this.changedItems, item.id);
    },

    getChangedItemsIds(included: boolean = false): number[] {
      return (
        Object.entries(this.changedItems)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, value]) => value === included)
          .map(([key]) => +key)
      );
    },

    getCountGamesLabel(count: number): string {
      return `${count} ${pluralize(count, ['game', 'games', 'games'])}`;
    },

    resetPage(): void {
      this.options.page = 1;
    },

    resetChangeItems(): void {
      this.changedItems = {};
    },

    resetSelectedGames(): void {
      this.selectedGames = [];
    },

    handleClickSearchButton(): void {
      this.resetPage();
      this.resetSelectedGames();
      this.resetChangeItems();
      this.updateGamesBlacklist();
    },

    handleClickSearchClearButton(): void {
      this.filter.search = null;
      this.resetPage();
      this.resetSelectedGames();
      this.resetChangeItems();
      this.updateGamesBlacklist();
    },

    submit(): void {
      this.$emit('submit', {
        included: this.includedIds,
        excluded: this.excludedIds
      });
    },

    handleClickCancelButton(): void {
      this.resetChangeItems();
      this.setInitialSelectedItems(this.rows);
      this.resetPage();
      this.updateGamesBlacklist();
      this.submit();
    },

    handleClickApplyButton(): void {
      this.submit();
    }
  }
};
