import { getData as getCountryList } from 'country-list'; //ISO 3166

import {
  SelectItem,
  EMissionSegment,
  EMissionRewardType,
  EMissionConditions,
  EMissionDevice,
  EMissionStatus
} from '@/api/schema';

export const MAX_FILE_SIZE = 1024 * 400;
export const IMAGE_REQUIREMENTS = `Image aspect ratio must be 1:1(recommended dimensions is 640 x 640 pixels) in JPEG, JPG or WEBP (file size – up to 400kb)`;

export const SEGMENT_OPTIONS = {
  [EMissionSegment.ALL]: 'All users',
  [EMissionSegment.WITHOUT_BET]: 'Users without Bet',
  [EMissionSegment.WITHOUT_H2E]: 'Users without H2E',
  [EMissionSegment.NEW]: 'New users',
  [EMissionSegment.CUSTOM]: 'Custom audience'
};
export const REWARD_TYPE_OPTIONS = {
  [EMissionRewardType.TOKEN_AMOUNT]: 'Token amount',
  [EMissionRewardType.H2E_COEFFICIENT]: 'Hold To Earn Coefficient',
  [EMissionRewardType.EXTRA_H2E_VOLUMES]: 'Extra Hold To Earn Volumes',
  [EMissionRewardType.P2E_WAGER_MULTIPLIER]: 'Play To Earn Wager Multiplier'
};
export const CONDITION_OPTIONS = {
  [EMissionConditions.H2E]: 'Hold to Earn',
  [EMissionConditions.BET]: 'Bet',
  [EMissionConditions.BET_VOLUME]: 'Bet Volume',
  [EMissionConditions.DEPOSIT]: 'Deposit',
  [EMissionConditions.WITHDRAW]: 'Withdraw'
};

export const DISABLED_CONDITION_OPTIONS = [
  EMissionConditions.DEPOSIT,
  EMissionConditions.WITHDRAW
];

export const STATUS_OPTIONS = {
  [EMissionStatus.ACTIVE]: 'Active',
  [EMissionStatus.DRAFT]: 'Draft',
  [EMissionStatus.DELAYED]: 'Delayed',
  [EMissionStatus.DEACTIVATED]: 'Deactivated'
};
export const DEVICE_OPTIONS = {
  [EMissionDevice.DESKTOP]: 'Desktop',
  [EMissionDevice.TABLE]: 'Table',
  [EMissionDevice.MOBILE]: 'Mobile'
};

export const formatListOptions = (
  options: {
    [key: string]: string;
  },
  disabledOptions: string[] = []
): SelectItem[] => {
  return Object.entries(options).map(([value, text]) => ({
    text,
    value,
    disabled: disabledOptions.includes(value)
  }));
};

export const segmentOptions = formatListOptions(SEGMENT_OPTIONS);
export const rewardTypeOptions = formatListOptions(REWARD_TYPE_OPTIONS);
export const conditionOptions = formatListOptions(
  CONDITION_OPTIONS,
  DISABLED_CONDITION_OPTIONS
);
export const deviceOptions = formatListOptions(DEVICE_OPTIONS);
export const statusOptions = formatListOptions(STATUS_OPTIONS);
export const countryOptions = getCountryList().map((country) => ({
  text: country.name,
  value: country.code
}));

export const defaultFormData = {
  missionImage: null,
  name: null,
  segment: null,
  location: [],
  device: [],
  operatorUserIds: [],
  condition: null,
  betConditionType: null,
  conditionAmount: null,
  noAccumulation: false,
  countable: true,
  oncePerDayCount: false,
  rewardAmount: null,
  rewardType: null,
  startDate: null,
  dueDate: null
};

export const excludedInFormData = [
  'createdAt',
  'updatedAt',
  'status',
  'id',
  'activeUsers'
];

export const enabledStatusConfig = {
  [EMissionStatus.ACTIVE]: [EMissionStatus.DEACTIVATED],
  [EMissionStatus.DRAFT]: [EMissionStatus.DEACTIVATED, EMissionStatus.DELAYED],
  [EMissionStatus.DEACTIVATED]: [EMissionStatus.ACTIVE, EMissionStatus.DRAFT],
  [EMissionStatus.DELAYED]: []
};

export const confirmationConfig = {
  [EMissionStatus.ACTIVE]: {
    title: 'Activate Mission',
    content: 'Please confirm the deactivation of',
    button: {
      text: 'Activate',
      color: 'success'
    }
  },
  [EMissionStatus.DEACTIVATED]: {
    title: 'Deactivate Mission',
    content: 'Please confirm the activation of',
    button: {
      text: 'Deactivate',
      color: 'warning'
    }
  },
  [EMissionStatus.DELETED]: {
    title: 'Delete Mission',
    content: 'Please confirm the deletion of',
    warn: 'You will not be able to recover the mission.',
    button: {
      text: 'Delete',
      color: 'error'
    }
  }
};
